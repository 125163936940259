import request from "@/utils/request"
import commonApi from "../api"

const moduleName = "/auth/user"

export default {
	...commonApi(moduleName),
	apiGetRoleList: data =>
		request(`${moduleName}/${data.id}/roles`, {
			method: "get"
		}),
	apiSetRoleList: data =>
		request(`${moduleName}/${data.id}/roles`, {
			method: "post",
			body: data
		}),
	apiResetPwd: data =>
		request(`${moduleName}/${data.id}/reset-pwd`, {
			method: "put",
			body: data
		}),
	apiUpdateSelfInfo: data =>
		request("/auth/self-info", {
			method: "put",
			body: data
		}),
	// 上传文件
	uploadFile: (data, params) =>
		request("/document/attachment/user-audit-file", {
			method: "post",
			body: data,
			params
		}),
	// 下载用户批量导入模板
	exportTemplate: () =>
		request("/auth/users/export-excel-template", {
			method: "get"
		}),
	// 下载用户批量导入模板
	importExcel: () =>
		request("/auth/users/import-excel", {
			method: "get"
		}),
	// 获取用户的项目列表及角色
	getProAndRole: data =>
		request(`/auth/user/${data.userId}/team-info`, {
			method: "get",
			params: data
		}),
	apiSetUserAndProject: data =>
		request(`/auth/user/${data.userId}/team-role`, {
			method: "post",
			body: data
		})
}
