<template>
	<div class="content-wrap user-project-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsAction>
			<Button
				:disabled="submitDisabled"
				type="primary"
				@click="submit"
				:loading="loading"
				>保存配置</Button
			>
		</CtmsAction>
		<Row :gutter="20" class="row-wrap">
			<Col span="12">
				<h2 class="title">用户列表</h2>
				<div class="input-search">
					<Input
						placeholder="用户名"
						v-model="userName"
						@on-search="handleUserSearch"
						search
						enter-button="查询用户"
					>
					</Input>
					<Button class="reset" @click="resetUser">重置</Button>
				</div>
				<CtmsDataGrid
					ref="userTable"
					highlight-row
					:height="dataGridHeight"
					:columns="columns"
					:data="listData"
					:page="page"
					:show-index="false"
				>
				</CtmsDataGrid>
			</Col>
			<Col span="12">
				<h2 class="title">项目及角色列表</h2>
				<div class="input-search">
					<Input
						placeholder="项目名称关键字"
						v-model="projectName"
						@on-search="searchTreeList"
						search
						enter-button="查询项目"
					></Input>
					<Button class="reset" @click="resetPro">重置</Button>
				</div>
				<div :style="{ height: dataGridHeight - 50 + 'px', overflow: 'auto' }">
					<Spin fix v-if="treeLoading"></Spin>
					<Tree
						:data="treeData"
						class="tree-wrap"
						ref="proTree"
						:check-directly="true"
						show-checkbox
					></Tree>
				</div>
			</Col>
		</Row>
	</div>
</template>

<script>
import api from "@/api/setting/staff"
import { mapState } from "vuex"

const { apiGetPage, getProAndRole, apiSetUserAndProject } = api

const transformTreeData = data => {
	if (data && data.length) {
		return data.map(item =>
			Object.assign(item, {
				children: transformTreeData(item.leaf),
				title: item.name,
				id: item.id,
				key: item.id,
				checked: item.checked,
				expand: item.expand
			})
		)
	}
	return []
}

export default {
	name: "index",
	data() {
		return {
			listData: [], // 左侧 用户列表
			treeData: [], // 右侧展示的项目树列表
			columns: [
				{
					title: "单选",
					align: "center",
					key: "checkBox",
					width: 80,
					render: (h, params) =>
						h("div", [
							h("Checkbox", {
								props: {
									value: params.row.checkBox
								},
								on: {
									"on-change": e => {
										if (e === false) {
											// 取消勾选
											this.userId = ""
											this.submitDisabled = true
											this.treeData = []
										} else {
											// 选中
											this.userId = params.row.id
											this.submitDisabled = false
											this.initTreeList()
										}
										this.listData.forEach(items => {
											// 先取消所有对象的勾选，checkBox设置为false
											this.$set(items, "checkBox", false)
										})
										this.listData[params.index].checkBox = e // 再将勾选的对象的checkBox设置为true
									}
								}
							})
						])
				},

				{
					title: "用户名",
					key: "username",
					minWidth: 200
				},
				{
					title: "真实姓名",
					key: "realName",
					minWidth: 120
				},
				{
					title: "部门/科室",
					key: "deptName",
					minWidth: 120
				}
			],
			loading: false,
			treeLoading: false,
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			userName: "",
			projectName: "",
			userId: "", // 当前选中用户id
			projectId: "", // 当前选中项目id
			submitDisabled: true, // 是否可以提交 保存配置
			isSearchTree: false, // 是否点击查询右侧项目树
			treeProjectIds: [] // 右侧项目树的projectIds
		}
	},
	created() {
		this.initUserList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		// 获取左侧用户列表
		initUserList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					username: this.userName,
					isDel: 0
				})
				if (res && res.data) {
					this.listData = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 获取右侧项目树
		initTreeList() {
			this.$asyncDo(async () => {
				this.treeLoading = true
				const res = await getProAndRole({
					userId: this.userId,
					projectName: this.projectName
				})
				if (res && res.data) {
					this.treeData = transformTreeData(res.data)
					if (this.isSearchTree && res.data.length) {
						this.treeProjectIds = res.data[0].children.map(item => item.id)
					}
				}
				this.treeLoading = false
			})
		},
		// 搜索用户功能
		handleUserSearch() {
			this.isSearchTree = false
			this.page.current = 1
			this.$nextTick(() => {
				this.initUserList()
			})
		},
		// 搜索项目
		searchTreeList() {
			this.isSearchTree = true
			this.initTreeList()
		},
		// 保存配置
		submit() {
			this.$Modal.confirm({
				title: "提示",
				content: "请确认是否要提交对用户的项目角色配置",
				onOk: () => {
					const arr = this.$refs.proTree.getCheckedAndIndeterminateNodes()
					// 全部 是最外层，id为-1，第二层为项目层级，pid都为 -1.
					let userProjectRoles = []
					if (arr.length) {
						userProjectRoles = arr
							.filter(item => item.pid === "-1")
							.map(item => ({
								projectId: item.id,
								roleIds: item.children.length
									? item.children
											.filter(role => role.checked)
											.map(role => role.id)
									: []
							}))
					}
					this.$asyncDo(async () => {
						this.loading = true
						let params = {
							userId: this.userId,
							userProjectRoles
						}
						// 如果是查询项目的情况下，传查询出来的项目id参数，只更新这些里面的项目。
						if (this.isSearchTree) {
							params = {
								...params,
								projectIds: this.treeProjectIds
							}
						}
						const res = await apiSetUserAndProject(params)
						if (res) {
							this.$Message.success("操作成功！")
							this.isSearchTree = false
							this.initTreeList()
						}
						this.loading = false
					})
				}
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initUserList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initUserList()
			})
		},
		resetUser() {
			this.isSearchTree = false
			this.userName = ""
			this.userId = ""
			this.initUserList()
			this.treeData = []
		},
		resetPro() {
			this.isSearchTree = false
			this.projectName = ""
			this.initTreeList()
		}
	}
}
</script>
<style lang="less">
.user-project-wrap {
	padding: 20px;
	.row-wrap {
		padding: 0 10px;
		.title {
			padding-top: 15px;
		}
		.input-search {
			margin: 20px 0;
			position: relative;
			.ivu-input-group {
				width: 320px;
			}
			.ivu-input {
				border-color: #2d8cf0;
			}
			.reset {
				position: absolute;
				left: 340px;
				top: 0;
			}
		}
	}
}
</style>
